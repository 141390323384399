
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { VSwitch } from "vuetify/lib";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "PendingCoursesPage",
  components: {
    BaseTooltipIconButton,
    BaseTableFiltered,
  },
  data: () => ({
    search: "",
  }),
  computed: {
    headers() {
      return [
        { text: "Navn", value: "courseName" },
        { text: "Handlinger", value: "actions" },
        {
          text: "Antall søkere",
          value: "numberOfApplicants",
        },
        {
          text: "Antall tilbud sendt",
          value: "numberOfOffers",
        },
        {
          text: "Antall på venteliste",
          value: "numberInQeueue",
        },
        {
          text: "Totalt antall plasser",
          value: "totalNumberOfSpots",
        },
        { text: "Opprettet av", value: "isMine" },
      ];
    },
    filter() {
      return [
        {
          component: VSwitch,
          value: "isMine",
          staticClass: "mx-3",
          default: true,
          attrs: {
            label: "Vis kun mine",
            inset: true,
          },
          apply: (value: string, model: string) =>
            // TODO is string right?
            model || value,
        },
      ];
    },
  },
});
