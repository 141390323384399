var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', [_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": [],
      "search": _vm.search,
      "filter": _vm.filter
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light text-center",
          staticStyle: {
            "align-self": "center"
          }
        }, [_c('BaseTooltipIconButton', {
          attrs: {
            "color": "primary",
            "btnText": "Tilbake til arbeidsflate",
            "icon": "mdi-arrow-left"
          },
          on: {
            "handleClick": function handleClick($event) {
              return _vm.$router.push("/home/");
            }
          }
        }), _vm._v(" Klasser ")], 1), _c('v-spacer'), _c('v-text-field', {
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk i testtyper",
            "single-line": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })];
      },
      proxy: true
    }, {
      key: "item.isMine",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? "Meg" : "Noen andre") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.$router.push('/');
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }])
        }, [_c('span', [_vm._v("Gå til klasse")])])];
      },
      proxy: true
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }